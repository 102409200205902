import React, { useEffect, useState } from 'react';
import { API_URL } from '../constants';
import axios from 'axios';

const Users = () => {
  const [activeTab, setActiveTab] = useState("order"); // State to manage active tab
  const [order, setOrder] = useState([]);
  const [sampling, setSampling] = useState([]);
  const removeTimestamp = (timestamp) => {
    if (!timestamp) {
      return "NA"; // Handle null or undefined timestamp
    }
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0'); // Ensure two-digit day
    const month = date.toLocaleString('default', { month: 'long' }); // Full month name
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };
  useEffect(() => {
    const userdata = localStorage.getItem('userId');

    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${API_URL}/getuserorders/${userdata}`);
        setOrder(response.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    const fetchSampling = async () => {
      try {
        const response = await axios.get(`${API_URL}/getusersampling/${userdata}`);
        setSampling(response.data);
      } catch (error) {
        console.error("Error fetching sampling:", error);
      }
    };

    if (activeTab === "order") {
      fetchOrders();
    } else if (activeTab === "sampling") {
      fetchSampling();
    }
  }, [activeTab]);

  return (
    <div className="container-fluid px-3 px-md-4 py-4 pt-100">
      <style>
        {`
          @media (max-width: 576px) {
            .table thead { display: none; }
            .table, .table tbody, .table tr, .table td { display: block; width: 100%; }
            .table tr { margin-bottom: 1rem; border: 1px solid #dee2e6; }
            .table td { text-align: right; padding: 0.5rem; position: relative; }
            .table td::before {
              content: attr(data-label);
              position: absolute;
              left: 0;
              padding-left: 0.5rem;
              font-weight: bold;
              text-align: left;
            }
          }
          .order-label { color: #369694; }
          .page-item.active .page-link { background-color: #369694; }
          .btn-primary { background-color: #369694 !important; }
        `}
      </style>

      <div className="card shadow">
        {/* Tabs */}
        <div className="card-header py-3">
          <ul className="nav nav-tabs card-header-tabs">
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === "order" ? "active" : ""}`}
                onClick={() => setActiveTab("order")}
              >
                Order Status
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === "sampling" ? "active" : ""}`}
                onClick={() => setActiveTab("sampling")}
              >
                Sampling Status
              </button>
            </li>
          </ul>
        </div>

        {/* Tab Content */}
        <div className="card-body p-0">
          <div className="table-responsive">
            {activeTab === "order" && (
              <table className="table table-bordered m-0">
                <thead className="bg-light">
                  <tr>
                    <th className="align-middle">Batch Id</th>
                    <th className="align-middle">SKU</th>
                    <th className="align-middle">Piece Count</th>
                    <th className="align-middle">Size</th>
                    <th className="align-middle">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {order.map((od, index) => (
                    <tr key={index}>
                      <td className="align-middle" data-label="Batch Id">{od.batchId}</td>
                      <td className="align-middle" data-label="SKU">{od.sku}</td>
                      <td className="align-middle" data-label="Piece Count">{od.pieceCount}</td>
                      <td className="align-middle" data-label="Size">{od.size}</td>
                      <td className="align-middle" data-label="Status">
                        <span className={`badge ${od.status === 'Active' ? 'bg-success' : 'bg-danger'}`}>
                          {od.status}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {activeTab === "sampling" && (
              <table className="table table-bordered m-0">
                <thead className="bg-light">
                  <tr>
                    <th className="align-middle">Sku</th>
                    <th className="align-middle">Sample Type</th>
                   
                    <th className="align-middle">Size</th>
                    <th className="align-middle">ETA</th>
                    <th className="align-middle">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {sampling.map((sp, index) => (
                    <tr key={index}>
                      <td className="align-middle" data-label="Sample Id">{sp.sku}</td>
                      <td className="align-middle" data-label="Material">{sp.sampleType}</td>
                      <td className="align-middle" data-label="Quantity">{sp.size}</td>
                      <td className="align-middle" data-label="Quantity">{removeTimestamp(sp.eta)}</td>
                      <td className="align-middle" data-label="Status">
                        <span className={`badge ${sp.status === 'Active' ? 'bg-success' : 'bg-danger'}`}>
                          {sp.status}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
