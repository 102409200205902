import './App.css';
import { Routes, Route, useLocation } from "react-router-dom"
import Footer from './Components/Footer';
import Header from './Components/header';
import Home from './Pages/Home';
import Login from './Pages/Login';
import GetAQuote from './Pages/GetAQuote';
import User from './Pages/User';
import ResetPassword from './Pages/ResetPassword';

import DesignServices from './Components/DesignServices';
import BlogPage from './Components/BlogPage';
import AboutUs from './Components/AboutUs';
import GuidelinesSilhouetteWomen from './Blogs/Guidelines to Silhouette (Women)';
import GuidelinesSilhouetteMen from './Blogs/Guidelines to Silhouette (Men)';
import GuidelinesSilhouetteKids from './Blogs/Guidelines to Silhouette (Kids)';
import GuidelinesEthnicKids from './Blogs/Guidelines to Ethnic (Kids)';
import GuidelinesEthnicMen from './Blogs/Guidelines to Ethnic (Men)';
import GuidelinesEthnicWomen from './Blogs/Guidelines to Ethnic (Women)';
import { useEffect } from 'react';
import Sidebar from './Components/Sidebar';
import { ToastContainer } from 'react-toastify';
import ProtectedRoute from './Components/ProtectedRoute';
import { AuthProvider } from './Auth/AuthContext';

function App() {
  const { pathname } = useLocation();
  var loadScript = function (src) {
    var tag = document.createElement('script');
    tag.async = true;
    tag.type = "module";
    tag.src = src;
    document.getElementsByTagName('body')[0].appendChild(tag);
  }
  useEffect(() => {
   
    loadScript('/js/bootstrap.bundle.min.js')
    // loadScript('/js/plugins.js')
    loadScript('/js/jquery.ajaxchimp.min.js')
 
    loadScript('/js/contact-form.js')
    // loadScript('/js/index.umd.js')
   
    
    loadScript('/js/all.js')
   
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
 }, [pathname]);

  return (
    <div className="App" >
      <div className="page" id="top">
      <AuthProvider>
        <Header />
        <ToastContainer />
        <Routes>
          
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/get-a-quote" element={<GetAQuote />} />
          <Route path="/design-services" element={<DesignServices />} />
          <Route path="/blogs" element={<BlogPage/>} />
          <Route path="/about-us" element={<AboutUs/>}/>
          <Route path="/reset-password/:token" element={<ResetPassword/>}/>
          <Route element={<ProtectedRoute />}>
           <Route path="/user" element={<User />} />
          </Route>
          <Route path="/guidelines-silhouette-women" element={<GuidelinesSilhouetteWomen/>}/>
          <Route path="/guidelines-silhouette-men" element={<GuidelinesSilhouetteMen/>}/>
          <Route path="/guidelines-silhouette-kids" element={<GuidelinesSilhouetteKids/>}/>
          <Route path="/guidelines-ethnic-women" element={<GuidelinesEthnicWomen/>}/>
          <Route path="/guidelines-ethnic-men" element={<GuidelinesEthnicMen/>}/>
          <Route path="/guidelines-ethnic-kids" element={<GuidelinesEthnicKids/>}/>
        </Routes>
        <Footer />
        </AuthProvider>
      </div>
    </div >
  );
}

export default App;
