import React, { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { API_URL } from '../constants';

const ResetPassword = () => {
    const navigate = useNavigate();
    const { token } = useParams();
   
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }

        try {
            const response = await axios.post(`${API_URL}/resetpassword/${token}`, { newPassword: password });
            toast.success(response.data.message);
            navigate('/login'); // Redirect to login after successful reset
        } catch (error) {
            toast.error(error.response?.data?.message || "Password reset failed");
        }
    };
    
    return (
        <div>
            <section className="page-section pb-100 pb-sm-60 bg-gray-light-1 bg-light-alpha-80 parallax-5" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'images/full-width-images/page-title-bg-5.jpg'})` }}>
                <div className="position-absolute top-0 bottom-0 start-0 end-0 bg-gradient-white"></div>
                <div className="container position-relative pt-50">
                    <div className="text-center">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <h1 className="hs-title-1 mb-0">
                                    <span className="wow charsAnimIn" data-splitting="chars">Reset Password</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-section pt-0">
                <div className="container wow fadeInUp">
                    <div className="tab-content tpl-minimal-tabs-cont section-text" id="myTabContent-1">
                        <div className="tab-pane fade show active" id="account-login" role="tabpanel" aria-labelledby="account-login-tab">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    <form className="form contact-form" onSubmit={handleSubmit}>
                                        <div className="mb-30">
                                            <div className="form-group">
                                                <label htmlFor="password">New Password</label>
                                                <input type="password" onChange={(e) => setPassword(e.target.value)} name="password" id="password" className="input-lg round form-control" placeholder="New Password" pattern=".{3,100}" required aria-required="true" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="confirmPassword">Confirm Password</label>
                                                <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} name="confirmPassword" id="confirmPassword" className="input-lg round form-control" placeholder="Confirm Password" pattern=".{5,100}" required aria-required="true" />
                                            </div>
                                        </div>
                                        <div className="row mb-30">
                                            <div className="col-6">
                                                <div className="text-end">
                                                    <button type="submit" className="submit_btn btn btn-mod btn-large btn-round btn-hover-anim" id="reset-btn"><span>Submit</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ResetPassword;
